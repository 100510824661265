var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.initialLoading ? _c('div', [_c('v-row', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  })], 1)], 1)], 1) : _c('DatatableWrapper', {
    attrs: {
      "loading": _vm.applicationLoading,
      "pagination": _vm.pagination,
      "title": "Session Applications"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.applicationHeader,
      "items": _vm.sessionApplications,
      "loading": _vm.applicationLoading,
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.platform",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          domProps: {
            "textContent": _vm._s((item === null || item === void 0 ? void 0 : item.platform) === 'ios' ? 'mdi-apple' : 'mdi-android')
          }
        })];
      }
    }, {
      key: "item.package",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.package) + " ")];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "item.version",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.version) + " ")];
      }
    }, {
      key: "item.version_code",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.version_code) + " ")];
      }
    }, {
      key: "item.sessions",
      fn: function fn(_ref6) {
        var _item$stats$sessions, _item$stats;
        var item = _ref6.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'app-sessions',
                query: {
                  application_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$sessions = item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.sessions) !== null && _item$stats$sessions !== void 0 ? _item$stats$sessions : 0)) + " ")])];
      }
    }, {
      key: "item.feedback",
      fn: function fn(_ref7) {
        var _item$stats$attachmen;
        var item = _ref7.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var _item$stats$feedback, _item$stats2;
              var on = _ref8.on,
                attrs = _ref8.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "primary--text",
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.$router.push({
                      name: 'app-sessions-feedback',
                      query: {
                        application_id: item.id
                      }
                    });
                  }
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$feedback = item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : _item$stats2.feedback) !== null && _item$stats$feedback !== void 0 ? _item$stats$feedback : 0)) + " ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Attachments: " + _vm._s(_vm._f("formatNumber")((_item$stats$attachmen = item.stats.attachments) !== null && _item$stats$attachmen !== void 0 ? _item$stats$attachmen : 0)))])])];
      }
    }, {
      key: "item.activities",
      fn: function fn(_ref9) {
        var _item$stats$screens, _item$stats5, _item$stats$events, _item$stats$errors, _item$stats6;
        var item = _ref9.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref10) {
              var _item$stats3, _item$stats4;
              var on = _ref10.on,
                attrs = _ref10.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "primary--text",
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.$router.push({
                      name: 'app-sessions-activities',
                      query: {
                        application_id: item.id
                      }
                    });
                  }
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm._f("formatNumber")(_vm._f("sum")([item === null || item === void 0 ? void 0 : (_item$stats3 = item.stats) === null || _item$stats3 === void 0 ? void 0 : _item$stats3.screens, item === null || item === void 0 ? void 0 : item.stats.events, item === null || item === void 0 ? void 0 : (_item$stats4 = item.stats) === null || _item$stats4 === void 0 ? void 0 : _item$stats4.errors]))) + " ")])];
            }
          }], null, true)
        }, [_c('ul', [_c('li', [_vm._v("Screens: " + _vm._s(_vm._f("formatNumber")((_item$stats$screens = item === null || item === void 0 ? void 0 : (_item$stats5 = item.stats) === null || _item$stats5 === void 0 ? void 0 : _item$stats5.screens) !== null && _item$stats$screens !== void 0 ? _item$stats$screens : 0)))]), _c('li', [_vm._v("Events: " + _vm._s(_vm._f("formatNumber")((_item$stats$events = item === null || item === void 0 ? void 0 : item.stats.events) !== null && _item$stats$events !== void 0 ? _item$stats$events : 0)))]), _c('li', [_vm._v("Errors: " + _vm._s(_vm._f("formatNumber")((_item$stats$errors = item === null || item === void 0 ? void 0 : (_item$stats6 = item.stats) === null || _item$stats6 === void 0 ? void 0 : _item$stats6.errors) !== null && _item$stats$errors !== void 0 ? _item$stats$errors : 0)))])])])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": item.created_at
          }
        })];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref12) {
        var headers = _ref12.headers,
          item = _ref12.item;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        })];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }