<template>
  <div v-if="initialLoading">
    <v-row style="margin-top: 24px">
      <v-col cols="6">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader type="table"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
  <DatatableWrapper
    v-else
    :loading="applicationLoading"
    :pagination="pagination"
    title="Session Applications"
  >
    <v-data-table
      :headers="applicationHeader"
      :items="sessionApplications"
      :loading="applicationLoading"
      disable-sort
      hide-default-footer
    >
      <template #item.platform="{ item }">
        <v-icon
          v-text="item?.platform === 'ios' ? 'mdi-apple' : 'mdi-android'"
        ></v-icon>
      </template>
      <template #item.package="{ item }">
        {{ item.package }}
      </template>
      <template #item.name="{ item }">
        {{ item.name }}
      </template>
      <template #item.version="{ item }">
        {{ item?.version }}
      </template>
      <template #item.version_code="{ item }">
        {{ item?.version_code }}
      </template>
      <template #item.sessions="{ item }">
        <v-btn
          class="primary--text"
          icon
          @click.stop="
            $router.push({
              name: 'app-sessions',
              query: { application_id: item.id },
            })
          "
        >
          {{ item?.stats?.sessions ?? 0 | formatNumber }}
        </v-btn>
      </template>
      <template #item.feedback="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              class="primary--text"
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop="
                $router.push({
                  name: 'app-sessions-feedback',
                  query: { application_id: item.id },
                })
              "
            >
              {{ item?.stats?.feedback ?? 0 | formatNumber }}
            </v-btn>
          </template>
          <span
            >Attachments: {{ item.stats.attachments ?? 0 | formatNumber }}</span
          >
        </v-tooltip>
      </template>
      <template #item.activities="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              class="primary--text"
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop="
                $router.push({
                  name: 'app-sessions-activities',
                  query: { application_id: item.id },
                })
              "
            >
              {{
                [item?.stats?.screens, item?.stats.events, item?.stats?.errors]
                  | sum
                  | formatNumber
              }}
            </v-btn>
          </template>
          <ul>
            <li>Screens: {{ item?.stats?.screens ?? 0 | formatNumber }}</li>
            <li>Events: {{ item?.stats.events ?? 0 | formatNumber }}</li>
            <li>Errors: {{ item?.stats?.errors ?? 0 | formatNumber }}</li>
          </ul>
        </v-tooltip>
      </template>
      <template #item.created_at="{ item }">
        <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <!--          <v-card class="pa-4" tile flat>-->
          <!--            <v-row>-->
          <!--              <v-col-->
          <!--                v-if="!isEmpty(item?.traits) "-->
          <!--                cols="12">-->
          <!--                <div class="mb-3 semi-bold">-->
          <!--                  Attributes-->
          <!--                </div>-->
          <!--                <div v-for="(trait, index) in Object.keys(item?.traits)" :key="index">-->
          <!--                  {{ trait }}: <span class="semi-bold">-->
          <!--                          {{ item?.traits[trait]}}-->
          <!--                        </span>-->
          <!--                </div>-->
          <!--              </v-col>-->
          <!--              <v-col v-ese>-->
          <!--                No traits available-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <!--          </v-card>-->
        </td>
      </template>
    </v-data-table>
  </DatatableWrapper>
</template>

<script>
import dayjs from "dayjs";
import { isEmpty } from "@/core/services/helper.service";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";

export default {
  components: { DatatableWrapper },
  data() {
    return {
      dayjs,
      isEmpty,
      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
      sessionApplications: [],
      totalApplicationCount: 0,
      applicationHeader: [
        {
          text: "Platform",
          value: "platform",
          width: 0,
          align: "center",
        },
        {
          text: "Package/Bundle ID",
          value: "package",
          cellClass: "no-wrap",
          width: 0,
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Version",
          value: "version",
          align: "center",
          width: 140,
        },
        {
          text: "Version code",
          value: "version_code",
          align: "center",
          width: 140,
        },
        {
          text: "Sessions",
          value: "sessions",
          align: "center",
          width: 140,
        },
        {
          text: "Feedback",
          value: "feedback",
          align: "center",
          width: 140,
        },
        {
          text: "Activities",
          value: "activities",
          align: "center",
          width: 140,
        },
        {
          text: "Created",
          value: "created_at",
          width: 0,
          cellClass: "no-wrap",
          align: "center",
        },
      ],
      sessionUsers: [],
      latestExpand: [],
      applicationLoading: false,
      initialLoading: true,
    };
  },
  computed: {
    lastId() {
      return (
        this.sessionApplications[this.sessionApplications.length - 1]?.id || ""
      );
    },
  },
  watch: {
    async "pagination.page"(newPage) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          page: newPage,
        },
      });

      this.pagination.page = newPage;
      this.getSessionApplications({ last_id: this.lastId, page: newPage });
    },
    totalApplicationCount(val) {
      this.pagination.totalItems = val;
    },
  },
  created() {
    this.getSessionApplications().then(() => {
      this.initialLoading = false;
    });
  },
  methods: {
    getSessionApplications({ last_id = "", page = this.pagination.page } = {}) {
      this.applicationLoading = true;
      const params = {
        last_id,
        app_id: this.$route.params.app_id || "",
        user_id: this.$route.query.user_id || "",
        device_id: this.$route.query.device_id || "",
        page,
      };

      return this.$coreApi
        .get(`${this.apiRoutes.core.session.allApps}?${this.getQuery(params)}`)
        .then(({ data }) => {
          this.sessionApplications = data.data;
          this.totalApplicationCount = data.meta.total || 0;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.applicationLoading = false;
        });
    },
  },
};
</script>
